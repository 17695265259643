<mat-dialog-content style="margin-top: 20px !important; padding:10px 70px">
    <ng-container *ngIf="canReadAllErrors">
        <p class="sub-header">Unit</p>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field>
                        <input [(ngModel)]="filter.sendingUnit" oninput="this.value = this.value.toUpperCase()" matInput
                            autocomplete="off" placeholder="Sending Unit">

                        <mat-label>Sending Unit</mat-label>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field>
                        <input [(ngModel)]="filter.unitReference" oninput="this.value = this.value.toUpperCase()"
                            matInput autocomplete="off" placeholder="Referenced Unit">
                        <mat-label>Referenced Unit</mat-label>
                    </mat-form-field>
                </p>
            </div>
        </div>
    </ng-container>

    <p class="sub-header">Time Period</p>
    <div class="row">
        <div class="col-md-6">
            <p>
                <mat-form-field class="input-with-datepicker">
                    <input readonly="true" (dateChange)="periodFromDateChange($event)" matInput
                        [matDatepicker]="periodfrom" placeholder="From" [value]="filter.fromDate"
                        [max]="filter.toDate || currentDate">
                    <mat-datepicker-toggle matSuffix [for]="periodfrom"></mat-datepicker-toggle>
                    <mat-datepicker #periodfrom></mat-datepicker>
                    <mat-label>From</mat-label>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field class="input-with-datepicker">
                    <input readonly="true" (dateChange)="periodToDateChange($event)" matInput [matDatepicker]="periodto"
                        placeholder="To" [value]="filter.toDate" [min]="filter.fromDate " [max]="currentDate">
                    <mat-datepicker-toggle matSuffix [for]="periodto">
                    </mat-datepicker-toggle>
                    <mat-datepicker #periodto></mat-datepicker>
                    <mat-label>To</mat-label>
                </mat-form-field>
            </p>
        </div>
    </div>

    <p class="sub-header">Error Properties</p>
    <div class="row">
        <div class="col-md-6">
            <p>
                <app-error-category-select [errorCategories]="errorCategories"
                    (categoryChanged)="categoryChanged($event)" [defaultSelected]="filter.errorCategory">
                </app-error-category-select>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field>
                    <mat-select placeholder="Event Code" [(value)]="filter.eventCode"
                        (selectionChange)="eventCodeChanged($event)">
                        <mat-option [value]="null"><em>All Events</em></mat-option>
                        <mat-option *ngFor="let eventCode of eventCodes" [value]="eventCode">
                            {{ eventCode | eventCode }}
                        </mat-option>
                    </mat-select>
                    <mat-label>Event Code</mat-label>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field>
                    <mat-select placeholder="Status" [(value)]="filter.status"
                        (selectionChange)="statusChanged($event)">
                        <mat-option [value]="null"><em>All Unarchived</em></mat-option>
                        <mat-option *ngFor="let status of statuses" [value]="status">
                            {{status | errorStatus}}
                        </mat-option>
                    </mat-select>
                    <mat-label>Status</mat-label>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field>
                    <input [(ngModel)]="filter.errorCode" matInput autocomplete="off" placeholder="Discovery Code">
                    <mat-label>Discovery Code</mat-label>
                </mat-form-field>
            </p>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-dialog-close mat-button>Close</button>
    <button mat-flat-button (click)="doErrorSearch()" class="submit-btn">Search</button>
</mat-dialog-actions>