import { ErrorRecordSearchRequest } from '../../model/error-search/ErrorRecordSearchRequest';
import { ErrorSearchResultRow } from '../../model/error-search/ErrorSearchResultRow';
import { createReducer, on } from '@ngrx/store';
import * as ErrorDetailsActions from './error-details.actions';
import { addMonths, format } from 'date-fns';

// --- STATE STRUCTURE ---

export interface ErrorDetailsState {
    request: ErrorRecordSearchRequest | null;
    results: ErrorDetailsSearchResult | null;
    rowSelection: RowSelection;
}

export interface ErrorDetailsSearchResult {
    rows: ErrorSearchResultRow[] | null;
    count: number;
}

export interface RowSelection {
    selectedRows: boolean[];
    allOnScreenSelected: boolean;
    allRowsSelected: boolean;
}

const initialErrorDetailsState: ErrorDetailsState = {
    request: {
        searchText: '',
        errorCode: '',
        unitReference: '',
        pageIndex: 0,
        pageSize: 20,
        fromDate: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        sendingUnit: '',
        status: 'New',
        errorCategory: '',
        eventCode: '',
        revisionState: '',
    } as ErrorRecordSearchRequest,
    results: null,
    rowSelection: {
        selectedRows: [],
        allOnScreenSelected: false,
        allRowsSelected: false
    }
};

// --- REDUCER ---

export const errorDetailsReducer = createReducer(
    initialErrorDetailsState,
    on(ErrorDetailsActions.loadResultsSuccess, (state, { rows, count }) => ({
        ...state,
        results: {
            rows,
            count: count ?? state.results?.count
        },
        rowSelection: {
            selectedRows: rows?.map(() => false) || [],
            allOnScreenSelected: false,
            allRowsSelected: false
        }
    })),

    on(ErrorDetailsActions.setSearchRequest, (state, { request }) => ({
        ...state,
        request
    })),

    on(ErrorDetailsActions.toggleRowSelection, (state, { index }) => {
        const selectedRows = state.rowSelection.selectedRows.map((value, idx) => idx === index ? !value : value);

        return {
            ...state,
            rowSelection: {
                ...state.rowSelection,
                selectedRows,
                allOnScreenSelected: selectedRows.every(Boolean),
                allRowsSelected: false,
            }
        };
    }),

    on(ErrorDetailsActions.toggleSelectAllVisible, (state) => {
        const newValue = !state.rowSelection.allOnScreenSelected;
        return {
            ...state,
            rowSelection: {
                ...state.rowSelection,
                allOnScreenSelected: newValue,
                selectedRows: state.results?.rows?.map(() => newValue) || [],
            }
        };
    }),

    on(ErrorDetailsActions.selectAllRows, (state) => ({
        ...state,
        rowSelection: {
            ...state.rowSelection,
            selectedRows: state.results?.rows?.map(() => true) || [],
            allOnScreenSelected: true,
            allRowsSelected: true
        }
    })),

    on(ErrorDetailsActions.clearSelection, (state) => ({
        ...state,
        rowSelection: {
            ...state.rowSelection,
            selectedRows: state.results?.rows?.map(() => false) || [],
            allOnScreenSelected: false,
            allRowsSelected: false
        }
    })),

    on(ErrorDetailsActions.paginate, (state, { pageSize, pageIndex }) => ({
        ...state,
        request: {
            ...state.request,
            pageSize,
            pageIndex
        },
    })),
);

export const getErrorDetailsState = (state: ErrorDetailsState) => state;
